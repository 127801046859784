import { render, staticRenderFns } from "./lang-and-contacts-button.vue?vue&type=template&id=5809a0cc&scoped=true&"
import script from "./lang-and-contacts-button.vue?vue&type=script&lang=ts&"
export * from "./lang-and-contacts-button.vue?vue&type=script&lang=ts&"
import style0 from "./lang-and-contacts-button.vue?vue&type=style&index=0&id=5809a0cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5809a0cc",
  null
  
)

export default component.exports