import { render, staticRenderFns } from "./autocomplete-for-objects.vue?vue&type=template&id=1d91a38d&"
import script from "./autocomplete-for-objects.vue?vue&type=script&lang=ts&"
export * from "./autocomplete-for-objects.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports