import { render, staticRenderFns } from "./hotel-fly-segments.vue?vue&type=template&id=05f190de&"
import script from "./hotel-fly-segments.vue?vue&type=script&lang=ts&"
export * from "./hotel-fly-segments.vue?vue&type=script&lang=ts&"
import style0 from "./hotel-fly-segments.vue?vue&type=style&index=0&id=05f190de&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports