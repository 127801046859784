import { render, staticRenderFns } from "./close-cross-btn.vue?vue&type=template&id=75f442f2&scoped=true&"
import script from "./close-cross-btn.vue?vue&type=script&lang=ts&"
export * from "./close-cross-btn.vue?vue&type=script&lang=ts&"
import style0 from "./close-cross-btn.vue?vue&type=style&index=0&id=75f442f2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f442f2",
  null
  
)

export default component.exports