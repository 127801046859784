import { render, staticRenderFns } from "./cancel-payment.vue?vue&type=template&id=48f1b852&scoped=true&"
import script from "./cancel-payment.vue?vue&type=script&lang=ts&"
export * from "./cancel-payment.vue?vue&type=script&lang=ts&"
import style0 from "./cancel-payment.vue?vue&type=style&index=0&id=48f1b852&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f1b852",
  null
  
)

export default component.exports