
import Component, { mixins } from "vue-class-component";

import {
  IHeaderPost,
  TLocaleType,
  TPaxes,
  IStartEnd, IModalPost
} from "applications/desktop/package-compare-app/components/root-app/types/root-app.types";

import SiteHeader from "applications/desktop/package-compare-app/components/root-app/ui/header.vue";
import RootCommmon from "applications/desktop/package-compare-app/components/root-app/root-app.common";
import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import eventBus from 'applications/desktop/eventBus'
import Navigation from "applications/desktop/package-compare-app/types/control-app.navigation.class";
import AccessPopup from "applications/desktop/accessibility/AccessPopup.vue";
import AccessBtn from "applications/desktop/accessibility/AccessBtn.vue";
import TermsBtn from "ui-modules/terms-button/terms-button.vue";
import LangAndContactsButton from "ui-modules/lang-and-contacts-button/lang-and-contacts-button.vue";
import LangAndContactsDialog from "ui-modules/lang-and-contacts-dialog/lang-and-contacts-dialog.vue";
import {Accessibility} from "applications/desktop/accessibility/accessibility";
import {PRODUCT_ORDER_MODULE} from 'applications/desktop/package-compare-app/store/store'
import {isEmpty} from "lodash";

@Component({
  components: {
    AccessPopup,
    AccessBtn,
    LangAndContactsButton,
    LangAndContactsDialog,
    SiteHeader,
    TermsBtn,
  }
})

export default class PackageCompareDesktopApp extends mixins(SearchResultsCommon) {

  isShowLangAndContactsDialog = false

  get productOrderModule() {
    return PRODUCT_ORDER_MODULE
  }

  async created() {
    await this.productOrderModule.getAgencyInfo()
    if(!!this.referralCode) {
      await this.resultStore.getReferralRule({referralCode: this.referralCode})
    }
  }

  get referralCode() {
    return this.$route.query.ref
  }

  get iShowSessionModal() {
    return this.resultStore.iShowSessionModal
  }
  get referralRule() {
    return this.resultStore.referralRule
  }
  get allDestinationPackages() {
    return isEmpty(this.resultStore.allDestinationsPackages)
  }

  get isOrderPage() {
    return [ 'order', 'orderById'].includes(this.$route.name)
  }

  get isPaymentsResultPages() {
    // errorPayment", "cancelPayment
    return [ 'errorPayment', 'cancelPayment', 'successPayment'].includes(this.$route.name)
  }

  get isHeaderVisible() {
    return !this.isOrderPage && !this.isPaymentsResultPages
  }

  get navigation(): string[] {
    const navigation = new Navigation(this.$route);
    return navigation.breadcrumbs;
  }

  get headerPost(): IHeaderPost {
    return {
      currentLocale: this.i18nStore.currentLocale,
      locales: this.i18nStore.locales,
      quantityFavoritePackages: this.quantityFavoritePackages,
      quantityComparablePackages: this.quantityComparablePackages,
      navigation: this.navigation,
      searchQuery: this.searchStore.searchQuery,
      searchStore: this.searchStore,
      goToComparablePackages: () => this.goToComparablePackages(),
      goToFavoritePackages: () => this.goToFavoritePackages(),
      goToHomePage: () => this.goToHomePage(),
      goToHotelsSelectionPage: () => this.goToHotelsSelectionPage()
    };
  }

  startAccessibility = new Accessibility();

  async onLocaleChange(locale: TLocaleType) {
    this.searchQuery.exactDates = false // TODO make exactDates after BE fix range dates
    this.i18nStore.changeLocale({ locale });
    await this.getCountriesDataPrimary({ isAnyCountry: true })
    if (this.$route.name === 'package-compare.search-results') {
      this.initializeResultPage({})
    }
    else if (this.isOrderPage) {
      this.i18nStore.setLangIsChangedOnOrder({ flag: true })
    }
  }

  setQueryPaxes(paxes: TPaxes) {
    this.searchStore.takeSearchQuerySnapshot()
    this.searchQuery.paxes = paxes
    eventBus.$emit('update-destinations')
  }

  setQueryDateInterval(interval: IStartEnd<string>) {
    this.searchStore.takeSearchQuerySnapshot()
    this.searchQuery.dateInterval = interval
    eventBus.$emit('update-destinations')
  }
  get isShowReferralModal() {
    return !!this.referralRule && this.modalPost?.component === 'referral-greetings'
  }
}
