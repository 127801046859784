import { render, staticRenderFns } from "./pax-complects.room-filter.vue?vue&type=template&id=b61b7290&"
import script from "./pax-complects.room-filter.vue?vue&type=script&lang=ts&"
export * from "./pax-complects.room-filter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports