import { render, staticRenderFns } from "./lang-and-contacts-dialog.vue?vue&type=template&id=b76bbe12&scoped=true&"
import script from "./lang-and-contacts-dialog.vue?vue&type=script&lang=ts&"
export * from "./lang-and-contacts-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./lang-and-contacts-dialog.vue?vue&type=style&index=0&id=b76bbe12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b76bbe12",
  null
  
)

export default component.exports